import React, { useMemo, useEffect, useState, useContext } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import axios from '../../../../axiosConfig';
import Header from "../../../../components/Header";
import { Box, Link, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Chip, TextField, Input, Badge } from '@mui/material';
import { Delete as DeleteIcon, ExpandMore as ExpandMoreIcon, Message as MessageIcon, UploadFile as UploadFileIcon } from '@mui/icons-material';
import PdfViewer from 'elements/PDF/PdfViewer';
const moment = require('moment-timezone');
import { useNavigate } from "react-router-dom";
import { AuthContext } from "components/AuthContext";
import ConfirmDialog from '../../../../elements/ConfirmDialog/ConfirmDialog';

const statusColors = {
  Initiated: 'warning',
  Processing: 'info',
  Finished: 'success',
};

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [openReUpload, setOpenReUpload] = useState(false);
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [message, setMessage] = useState("");
  const [newDocuments, setNewDocuments] = useState([]);
  const [dialogInfo, setDialogInfo] = useState({ open: false, title: '', message: '' });
  const handleCloseDialog = () => setDialogInfo({ open: false, title: '', message: '' });

  const { user } = useContext(AuthContext);

  const handleOpen = (documents) => {
    setCurrentDocuments(documents);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentDocuments([]);
  };


// Convert UTC to IST when displaying the data
function displayAsIST(utcDate) {
  return moment(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
}

  const handleOpenMessages = async(row) => {
    setCurrentRow(row);
    const invoiceId = row.original._id;
    let commentsThread = [...row.original.commentsThread];
  
    const unreadComments = commentsThread.filter(comment => 
      (comment.author === 'Admin') && !comment.read);
  
    if (unreadComments.length > 0) {
      commentsThread = commentsThread.map(comment => 
        unreadComments.includes(comment) ? { ...comment, read: true } : comment
      );
  
      const { author, fromAndTo } = unreadComments[0];
      await updateComments({ invoiceId, author, fromAndTo });
      const updatedRow = { 
        ...row.original, 
        commentsThread 
      };
      setCurrentRow({ ...row, original: updatedRow });
    }
    setOpenMessages(true);
  };

  const handleCloseMessages = () => {
    setOpenMessages(false);
    setCurrentRow(null);
  };

  const handleSendMessage = async() => {
  if (message.trim()) {
    if (!currentRow.original.commentsThread) {
      currentRow.original.commentsThread = [];
    }
    currentRow.original.commentsThread.push({ 
      author: 'Client', 
      message: message, 
      fromAndTo: '1', 
      timestamp: new Date().toISOString() 
    });
    const updateData = {
        id: currentRow.original._id,
        commentsThread: currentRow.original.commentsThread
    };
    await updateUser(updateData);
    setMessage("");
  }
};


  const handleOpenReUpload = (row) => {
    setCurrentRow(row);
    setOpenReUpload(true);
  };

  const handleCloseReUpload = () => {
    setOpenReUpload(false);
    setCurrentRow(null);
    setNewDocuments([]);
  };

  const handleFileChange = (e) => {
    setNewDocuments([...e.target.files]);
  };

  const handleReUpload = async () => {
    if (newDocuments.length > 0) {
      const formData = new FormData();
      newDocuments.forEach((file) => {
        formData.append('documents', file);
      });
      formData.append('id', currentRow.original._id);
      formData.append('fromAndTo', '1');

      try {
        await axios.post('/invoices/reupload_documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setDialogInfo({
          open: true,
          title: 'Success',
          message: `Documents Uploaded assigned successfully.`,
        });
      } catch (error) {
        console.error('Error during file upload:', error);
        setDialogInfo({
          open: true,
          title: 'Error',
          message: error,
        });
      }

      // Refetch data or update local state accordingly
      refetchInvoices();
      handleCloseReUpload();
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'createdAt', header: 'CreatedAt',
      Cell: ({ row }) => (
        displayAsIST(row.original.createdAt)
      ),
    },
    {
      accessorKey: 'sub_category', header: 'Sub-category',
    //   muiEditTextFieldProps: {
    //     required: true,
    //     error: !!validationErrors?.sub_category,
    //     helperText: validationErrors?.sub_category,
    //     onFocus: () => setValidationErrors({ ...validationErrors, sub_category: undefined }),
    //   },
    },
    {
      accessorKey: 'documents', header: 'Documents',
      Cell: ({ row }) => (
        <Link
          component="button"
          variant="body2"
          onClick={() => handleOpen(row.original.uploadedDocuments)}
        >
          View Documents
        </Link>
      ),
    },
    {
      accessorKey: 'status', header: 'Status',
      Cell: ({ row }) => (
        <Chip
          label={row.original.status}
          color={statusColors[row.original.status]}
          variant="outlined"
        />
      ),
      enableEditing: false,
    },
    {
      accessorKey: 'messages', header: 'Messages', size: 100,
      Cell: ({ row }) => {
        const hasUnreadMessages = row.original.commentsThread.filter(comment => !comment.read && (comment.author === 'Admin'));
        return(
        <Tooltip title="Message">
          {hasUnreadMessages.length > 0 ? 
          <IconButton color="secondary" onClick={() => handleOpenMessages(row)}>
            <Badge badgeContent={hasUnreadMessages.length} color="error">
              <MessageIcon />
            </Badge>
          </IconButton> :
          <IconButton color="primary" onClick={() => handleOpenMessages(row)}>
            <MessageIcon />
          </IconButton>
        }
        </Tooltip>
      )},
    },
    {
      header: 'Upload',
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Re-upload Documents">
            <IconButton color="primary" onClick={() => handleOpenReUpload(row)}>
              <UploadFileIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], [validationErrors]);
  
  

  const { data: fetchedInvoices = [], isLoading: isLoadingInvoices, isError: isLoadingInvoicesError, isFetching: isFetchingInvoices, refetch: refetchInvoices } = useGetInvoices(user);
  const { mutateAsync: updateUser } = useUpdateInvoice();
  const { mutateAsync: deleteUser } = useDeleteInvoice();
  const { mutateAsync: updateComments } = useMarkMessagesAsRead();

  const openDeleteConfirmModal = async(row) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
        try {
          await deleteUser(row.original._id);
        } catch (error) {
          console.error('Failed to delete invoice:', error);
        }
      }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedInvoices,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingInvoicesError
      ? { color: 'error', children: 'Error loading data' }
      : undefined,
    muiTableContainerProps: { sx: { minHeight: '500px' } },
    muiTableBodyCellProps: {},
    state: {
      isLoading: isLoadingInvoices,
      showAlertBanner: isLoadingInvoicesError,
      showProgressBars: isFetchingInvoices,
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <span>
            <IconButton color="error" onClick={() => openDeleteConfirmModal(row)} disabled={row.original.status !== 'Initiated'}>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {refetchInvoices()}}
      >
        Refresh
      </Button>
    ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>View Documents</DialogTitle>
        <DialogContent>
          {currentDocuments
          .filter((document) => document.fromAndTo === '1')
          .map((document, index) => {
            const fileExtension = document.filename.split('.').pop().toLowerCase();
            const renderDocument = () => {
              switch (fileExtension) {
                case 'pdf':
                  return <PdfViewer key={index} fileUrl={document.url} />;
                  case 'jpg':
                  case 'jpeg':
                  case 'png':
                  case 'gif':
                    return (<>
                    <img src={document.url} alt={`Document ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                      <button
                      onClick={() => window.open(document.url, '_blank')}
                      className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      Download Document
                    </button>
                    </>
                    )
                default:
                  return (
                    <button
                      onClick={() => window.open(document.url, '_blank')}
                      className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      View/Download Document
                    </button>
                  );
              }
            };
            return(
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Document {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderDocument()}
              </AccordionDetails>
            </Accordion>
          )})}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMessages} onClose={handleCloseMessages} maxWidth="md" fullWidth>
        <DialogTitle>Thread Messages</DialogTitle>
        <DialogContent>
        {currentRow?.original.commentsThread
        .filter((msg) => msg.fromAndTo === "1")
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .map((msg, index) => (
            <Typography key={index} variant="body2" sx={{ padding: '5px', backgroundColor: msg.author === 'Client' ? 'lightblue' : 'lightgreen', borderRadius: '5px', marginBottom: '5px' }}>
            {msg.author}: {msg.message}
            </Typography>
        ))}
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            label="New Message"
            fullWidth
            multiline
            rows={4}
            sx={{ marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendMessage} variant="contained">Send</Button>
          <Button onClick={handleCloseMessages}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openReUpload} onClose={handleCloseReUpload} maxWidth="md" fullWidth>
        <DialogTitle>Re-upload Documents</DialogTitle>
        <DialogContent>
          <Input
            type="file"
            inputProps={{ multiple: true }}
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReUpload} variant="contained">Upload</Button>
          <Button onClick={handleCloseReUpload}>Close</Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={dialogInfo.open}
        onClose={handleCloseDialog}
        title={dialogInfo.title}
        message={dialogInfo.message}
      />
    </>
  );
};

const validateRequired = (value) => !!value.length;

function validateUser(service) {
  return {
    department: !validateRequired(service.department) ? 'Department is Required' : '',
    service: !validateRequired(service.service) ? 'Service is Required' : '',
  };
}

// Fake hooks and data

function useGetInvoices(user) {
  return useQuery({
    queryKey: ['invoices'],
    queryFn: async () => {
      const response = await axios.post('/invoices/client', {clientEmail: user.username});
      return response.data.responseData;
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdateInvoice() {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: async (updateData) => {
        // Make an API call to update the invoice
        const response = await axios.post(`/invoices/update/${updateData.id}`, updateData);
        return response.data;
      },
      onMutate: async (updatedInvoice) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['invoices']);
  
        // Snapshot the previous value
        const previousInvoices = queryClient.getQueryData(['invoices']);
  
        // Optimistically update to the new value
        queryClient.setQueryData(['invoices'], (old) =>
          old.map((invoice) =>
            invoice.id === updatedInvoice.id ? { ...invoice, ...updatedInvoice } : invoice
          )
        );
  
        // Return a context object with the snapshotted value
        return { previousInvoices };
      },
      onError: (err, updatedInvoice, context) => {
        // Roll back to the previous value if the mutation fails
        queryClient.setQueryData(['invoices'], context.previousInvoices);
      },
      onSettled: () => {
        // Always refetch after error or success:
        queryClient.invalidateQueries(['invoices']);
      },
    });
  }

function useDeleteInvoice() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (invoiceID) => {
        const response = await axios.post(`/invoices/delete/${invoiceID}`);
        return response.data;
      },
      onMutate: (invoiceID) => {
        queryClient.setQueryData(['invoices'], (prevInvoices) =>
          prevInvoices?.filter((invoice) => invoice.id !== invoiceID)
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['invoices']);
      },
      onError: (error) => {
        console.error('Error deleting invoice:', error);
      },
    });
  }

  function useMarkMessagesAsRead() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async ({ invoiceId, author, fromAndTo }) => {
        await axios.post(`/invoices/${invoiceId}/markMessagesAsRead`, {
          author,
          fromAndTo,
        });
      },
      onMutate: async ({ invoiceId, author, fromAndTo }) => {
        await queryClient.cancelQueries(['invoices']);
        const previousInvoices = queryClient.getQueryData(['invoices']);
        queryClient.setQueryData(['invoices'], (prevInvoices) =>
          prevInvoices?.map((invoice) =>
            invoice._id === invoiceId
              ? {
                  ...invoice,
                  commentsThread: invoice.commentsThread.map((message) =>
                    message.author === author && message.fromAndTo === fromAndTo
                      ? { ...message, read: true } // Mark as read in UI
                      : message
                  ),
                }
              : invoice
          )
        );
        return { previousInvoices };
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(['invoices'], context.previousInvoices);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['invoices']);
      },
    });
  }

const queryClient = new QueryClient();

export default function App() {

  const [searchTerm, setSearchTerm] = useState('');
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [placeholderText, setPlaceholderText] = useState('Search through all our services');
  const placeholderOptions = [
    'Search through all our services',
    'Try "service"',
    'Find your best solution',
  ];
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices();
    let index = 0;
    let charIndex = 0;
    let currentText = '';
  
    const typeText = () => {
      if (charIndex < placeholderOptions[index].length) {
        currentText += placeholderOptions[index][charIndex];
        setPlaceholderText(currentText);
        charIndex += 1;
        setTimeout(typeText, 100);
      } else {
        setTimeout(() => {
          index = (index + 1) % placeholderOptions.length;
          currentText = '';
          charIndex = 0;
          setTimeout(typeText, 100);
        }, 2000);
      }
    };
  
    typeText();
  
    return () => clearTimeout(typeText);
  }, []);

  const fetchServices = async () => {
    const response = await axios.post('/services/get_all_services', {});
    setServices(response.data.responseData);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      const results = services.filter((service) =>
        service.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredServices(results);
    } else {
      setFilteredServices([]);
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Box m="20px">
        <div className="relative w-full max-w-2xl mx-auto">
          <input
            type="text"
            className="w-full px-6 py-4 text-lg text-gray-700 bg-white border border-gray-300 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-theme-purple"
            placeholder={placeholderText}
            value={searchTerm}
            onChange={handleSearch}
          />
          {filteredServices.length > 0 && (
            <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-20">
              {filteredServices.map((service, index) => (
                <li
                  key={index}
                  className="px-4 py-3 text-gray-700 hover:bg-gray-100 cursor-pointer border-b last:border-none"
                  onClick={() => navigate(`/client/apply/${JSON.stringify(service.name)}/${JSON.stringify("")}?additionalData=${encodeURIComponent(JSON.stringify(service.subCategories))}`)}
                >
                  {service.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <Header title="INVOICES" subtitle="List of services availed" />
        <Box
          m="40px 0 0 0"
          height="75vh"
        >
          <Example />
        </Box>
      </Box>
    </QueryClientProvider>
  );
}
