import React, { useContext } from 'react';
import { Fade } from 'react-awesome-reveal';
import NotFound from 'assets/images/NotFound.png';
import Button from 'elements/Button';
import { AuthContext } from 'components/AuthContext';

export default function PortfolioDetail({ data }) {
  const { user } = useContext(AuthContext);
  if (data === null) {
    return (
      <section className="container mx-auto">
        <Fade bottom>
          <div className="flex flex-col w-full justify-center">
            <div className="flex w-full justify-center">
              <img src={NotFound} alt="Not Found" className="sm:w-3/4 xl:w-5/12 mt-5" />
            </div>
            <h1 className="text-theme-blue text-2xl text-center mt-5">
              Service Not Found
            </h1>
            <div className="flex justify-center">
              <Button href={!user ? "/services" : user.role === "Client" ? '/client' : ""} type="link" className="flex w-30 h-10 px-5 mt-5 bg-theme-blue text-white items-center rounded transform transition duration-500 hover:bg-gray-900">
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  return (
    <section className="container mx-auto">
      <Fade bottom>
        <Button type="link" href={!user ? "/services" : user.role === "Client" ? '/client' : ""} className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline">
          <svg className="w-5 h-5 text-gray-400 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          See All Services
        </Button>
      </Fade>

        <div className="flex flex-col mt-8 justify-center" key={data._id}>
          <Fade bottom>
            <h1 className="text-5xl text-theme-blue text-center font-bold">{data.name}</h1>
            {/* <p className="font-light text-xl text-gray-400 text-center mb-10">{item.type}</p> */}
          </Fade>

          <Fade bottom delay={300}>
            <div className="flex justify-center xl:mb-6">
              <img src={data.photo} alt="Service" className="flex w-4/5 sm:w-4/6 rounded-lg shadow-lg" />
            </div>
          </Fade>

          <Fade bottom delay={300}>
            <div className="flex flex-col mt-16 mb-12 mx-8 sm:mx-16 xl:mx-28">
              <h1 className="text-3xl text-theme-blue font-bold mb-3">Service Detail</h1>
              <p className="font-light text-lg text-gray-400 text-justify">{data.description + " ."}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>

            <div className="flex flex-col mx-8 sm:mx-16 xl:mx-28">
              <h1 className="text-3xl text-theme-blue font-bold mb-5">Service Responsibilities</h1>
              <div className="flex flex-row flex-wrap gap-4 ml-1">
                {data.subCategories.map((responsibility, index) => (
                  <div key={index} className="px-6 py-3 text-theme-purple border border-theme-purple rounded-full shadow-lg">
                    {responsibility.name}
                  </div>
                ))}
              </div>
            </div>
            <div style={{
                "display": "flex",
                justifyContent: "center",
                marginTop:"10px"
            }}>
              <p className="text-red-500 text-sm mt-2">
                *Please login to see further details.
              </p>
            </div>
          </Fade>

          {user && (
            <Fade bottom delay={300}>
              <div className="flex flex-col mt-8 mx-8 sm:mx-16 xl:mx-28">
                <h1 className="text-3xl text-theme-blue font-bold mb-5">Additional Information</h1>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                  <div className="p-4 border rounded-lg shadow-md">
                    <h2 className="text-2xl text-theme-blue font-semibold mb-2">Timeline</h2>
                    <p className="text-lg text-gray-400">{data.timeline}</p>
                  </div>

                  <div className="p-4 border rounded-lg shadow-md">
                    <h2 className="text-2xl text-theme-blue font-semibold mb-2">Application Fee</h2>
                    <p className="text-lg text-gray-400">{data.applicationFee}</p>
                  </div>

                  <div className="p-4 border rounded-lg shadow-md">
                    <h2 className="text-2xl text-theme-blue font-semibold mb-2">Service Charge</h2>
                    <p className="text-lg text-gray-400">
                      {data.serviceCharge}
                    </p>
                  </div>

                  {/* <div className="p-4 border rounded-lg shadow-md">
                    <h2 className="text-2xl text-theme-blue font-semibold mb-2">Documents to be Submitted</h2>
                    <p className="text-lg text-gray-400">
                      {data.documents.join(', ')}
                    </p>
                  </div> */}
                </div>

                <div className="flex justify-center mt-8">
                  <Button href={`/client/apply/${JSON.stringify(data.name)}?additionalData=${encodeURIComponent(JSON.stringify(data.subCategories))}`} type="link" className="flex w-40 h-12 bg-gradient-to-r from-purple-500 to-blue-600 text-white items-center justify-center rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
                    Link to Apply
                  </Button>
                </div>
              </div>
            </Fade>
          )}
        </div>
    </section>
  );
}
