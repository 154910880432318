import React, { useState, useEffect } from 'react';
import axios from '../../../../axiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, Button, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions,
  Divider
} from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Check as CheckIcon, Close as CloseIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { useGlobalState } from '../../store';
import { district_mapping } from 'pages/Admin/data/mockData';
import { professionMapping } from 'pages/Admin/data/mockData';

const AdminApproval = () => {
  const [requests, setRequests] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewRequest, setViewRequest] = useState(null);
  const [declineReason, setDeclineReason] = useState('');

  // Access global state and updater function
  const { updateStoreAfterApproval } = useGlobalState();

  useEffect(() => {
    // Fetch pending requests on component mount
    const fetchPendingRequests = async () => {
      try {
        const response = await axios.post('/requests/get_pending_requests');
        setRequests(response.data.responseData);
      } catch (error) {
        console.error('Error fetching pending requests:', error);
      }
    };

    fetchPendingRequests();
  }, []);

  const generateUserID = async(role, district, profession) => {
    const districtCode = district_mapping[district];
    const professionCode = professionMapping[profession];
    let professionCountPadded = String(999).padStart(3, '0')
    let districtProfessionCountPadded = String(999).padStart(2, '0');

    try {
      const response = await axios.post('/requests/accounts_count', { role, profession, district });
      if (response.data.status === 200) {
        professionCountPadded = String(response.data.responseData.countOfProfession + 1).padStart(3, '0');
        districtProfessionCountPadded = String(response.data.responseData.countOfProfessionInDistrict + 1).padStart(2, '0');
      } 
    } catch (err) {
      console.log('Error fetching account counts',err);
    }
    
    return `${districtCode}${professionCountPadded}${professionCode}${districtProfessionCountPadded}`;
  };
  

  const handleApprove = async () => {
    if (!selectedRequest) return;

    const loginCred = {
      username,
      password,
      detailsId: selectedRequest._id,
      role: selectedRequest.role,
      email: selectedRequest.email,
      userId,
      name: selectedRequest.name,
    };

    try {
      const response = await axios.post('/requests/approve_signup', loginCred);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        // Optionally, remove the approved request from the pending requests list
      setRequests((prevRequests) => prevRequests.filter((r) => r._id !== selectedRequest._id));
        // Update global state and remove the approved request
        await updateStoreAfterApproval();
      } else {
        toast.error(response.data.message);
      }
      console.log('Successfully approved:', response.data);

      handleCloseDialog();
    } catch (error) {
      console.error('Error approving request:', error);
    }
  };

  const handleDecline = async () => {
    if (!selectedRequest) return;

    try {
      const response = await axios.post('/requests/decline_signup', {
        id: selectedRequest._id,
        role: selectedRequest.role,
        email: selectedRequest.email,
        reason: declineReason,
      });
      if (response.data.status === 200) {
        toast.success(response.data.message);
        await updateStoreAfterApproval();
        setRequests((prevRequests) => prevRequests.filter((r) => r._id !== selectedRequest._id));
      } else {
        toast.error(response.data.message);
      }
      console.log('Successfully declined:', response.data);
      handleCloseDeclineDialog();
      setDeclineReason("");
    } catch (error) {
      console.error('Error declining request:', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
    setUsername('');
    setPassword('');
  };

  const handleOpenDialog = async(request) => {
    setSelectedRequest(request);
    // Fetch the count of approved associates or outlets
    try {
      const generatedUsername =  await generateUserID(request.role, request.address.district, request.profession, 0,0)
      setUserId(generatedUsername);
      setUsername(request.email)
      // Generate a random number between 100000 and 999999
      const password = Math.floor(100000 + Math.random() * 900000);
      setPassword(password.toString())
    } catch (error) {
      console.error('Error fetching approved count:', error);
      // setUsername(`${request.profession}${request.address.city}`);
    }
    setOpenDialog(true);
  };

  const handleCloseDeclineDialog = () => {
    setOpenDeclineDialog(false);
    setSelectedRequest(null);
    setDeclineReason("")
  };

  const handleOpenDeclineDialog = (request) => {
    setSelectedRequest(request);
    setOpenDeclineDialog(true);
  };

  const handleOpenViewDialog = (request) => {
    setViewRequest(request);
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setViewRequest(null);
    setOpenViewDialog(false);
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Pending Sign-Up Requests
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Role</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Mobile</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Profession</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Address</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Registration Number / Office</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Services</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests?.map((request) => (
              <TableRow key={request._id}>
                <TableCell>{request.role}</TableCell>
                <TableCell>{request.name}</TableCell>
                <TableCell>{request.email}</TableCell>
                <TableCell>{request.mobile}</TableCell>
                <TableCell>{request.profession}</TableCell>
                <TableCell>
                  {request.address ? (
                    <div>
                      <div>{request.address.street}</div>
                      <div>{request.address.village}, {request.address.taluk},  {request.address.district}, {request.address.state}</div>
                    </div>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
                <TableCell>{request.register_office_name ? request.register_office_name : request.registration_number}</TableCell>
                <TableCell>
                  <Tooltip title="View Services">
                    <IconButton
                      color="info"
                      onClick={() => handleOpenViewDialog(request)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Approve">
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenDialog(request)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reject">
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeclineDialog(request)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Assign Username and Password</DialogTitle>
        <DialogContent>
          <TextField
            label="User ID"
            fullWidth
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Username"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{mb: 2 }}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
            variant="outlined"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApprove}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeclineDialog} onClose={handleCloseDeclineDialog} fullWidth  maxWidth="sm">
        <DialogTitle>Confirm Decline</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to decline this request?</Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Reason for Decline"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)} // Update state for the reason
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeclineDialog} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDecline}
            disabled={!declineReason.trim()} // Disable button if reason is empty
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openViewDialog} onClose={handleCloseViewDialog} maxWidth="md" fullWidth>
      <DialogTitle>View Details</DialogTitle>
      <DialogContent>
        {viewRequest && (
          <Box>
            <Typography variant="h6" sx={{ mt: 2 }}><strong>Services:</strong></Typography>
            <Box sx={{ mt: 2 }}>
              {viewRequest.services.map(service => (
                <Accordion key={service.serviceName}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${service.serviceName}-content`}
                    id={`panel-${service.serviceName}-header`}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{service.serviceName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {service.subCategories.map((subCategory, index) => (
                        <span key={index}>
                          {subCategory}{index < service.subCategories.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseViewDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      <ToastContainer />
    </Box>
  );
};

export default AdminApproval;
