import React, { useState } from 'react';
import axios from '../axiosConfig';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'elements/Button';
import Form from 'elements/Form';
import Header from 'parts/Header';

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`/api/auth/reset-password/${token}`, { password });
      if (response.data.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Header />

      {/* Background with gradient and animation */}
      <div className="relative flex items-center justify-center">
        {/* Content container with shadow and rounded corners */}
        <div className="relative z-10 bg-white p-10 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-500 mb-6 text-center">
            Reset Password
          </h2>
          <p className="text-gray-600 text-center mb-8">
            Enter your new password and confirm it below.
          </p>

          {/* Password input field */}
          <Form
            id="password"
            name="password"
            type="password"
            value={password}
            placeholder="New Password"
            onChange={handleChange}
            className="w-full py-2 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
          />

          {/* Confirm Password input field */}
          <Form
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={handleConfirmPasswordChange}
            className="w-full py-2 px-4 mt-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
          />

          {/* Submit button with validation */}
          <Button
            onClick={handleSubmit}
            disabled={password !== confirmPassword || password.length < 6}
            className={`w-full py-3 mt-6 ${
              password !== confirmPassword || password.length === 0
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-gradient-to-r from-blue-600 to-purple-500 text-white"
            } text-xl rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105`}
          >
            Reset Password
          </Button>

          {/* Validation Message */}
          {password !== confirmPassword && (
            <p className="text-red-500 text-sm mt-2">
              Password and Confirm Password do not match.
            </p>
          )}
          {password.length < 6 && (
            <p className="text-red-500 text-sm mt-2">
              Password must be at least 6 characters long.
            </p>
          )}
        </div>
      </div>


      {/* Toast notifications */}
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
