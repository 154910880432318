import React, { useMemo, useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import TransferIcon from '@mui/icons-material/TransferWithinAStation';
import Header from "../../../../components/Header";
import { Box, Select, MenuItem, Link, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Checkbox, FormControlLabel, Grid, Card, CardContent,Divider,Badge,ToggleButtonGroup,ToggleButton } from '@mui/material';
import { Description as DescriptionIcon, Delete as DeleteIcon, Message as MessageIcon, ExpandMore as ExpandMoreIcon, Upload as UploadFileIcon, Send as SendIcon } from '@mui/icons-material';
import PdfViewer from 'elements/PDF/PdfViewer';
import axios from '../../../../axiosConfig';
const moment = require('moment-timezone');
import { useGlobalState } from "../../store";
import ConfirmDialog from '../../../../elements/ConfirmDialog/ConfirmDialog';


// Dummy data
const fakeData = [
];

const Example = () => {
  const { associates, outlets } = useGlobalState();
  const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openSelection, setOpenSelection] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [message, setMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectionType, setSelectionType] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [dialogInfo, setDialogInfo] = useState({ open: false, title: '', message: '' });
  const handleCloseDialog = () => setDialogInfo({ open: false, title: '', message: '' });
  const [messageView, setMessageView] = useState('Client');
  
  // const handleTransfer = (invoiceId) => {
  //   // Logic to transfer the invoice to another user
  //   console.log(`Transfer invoice ${invoiceId} to another user`);
  // };

  const handleMessageViewChange = (event, newView) => {
    if (newView) setMessageView(newView);
  };

  const handleOpen = (row) => {
    // console.log("row.original.uploadedDocuments = ",row.original.uploadedDocuments)
    setCurrentDocuments(row.original.uploadedDocuments);
    setCurrentRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentDocuments([]);
    setCurrentRow(null);
  };

  // Convert UTC to IST when displaying the data
function displayAsIST(utcDate) {
  return moment(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
}

  const handleUpload = (row) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    
    fileInput.onchange = async (e) => {
      const files = Array.from(e.target.files);
  
      const formData = new FormData();
      formData.append('id', row.original._id);
      formData.append('fromAndTo', '3');
  
      files.forEach((file) => {
        formData.append('documents', file);
      });
  
      try {
        await updateDocuments(formData); 
        setDialogInfo({
          open: true,
          title: 'Success',
          message: `Documents Uploaded assigned successfully.`,
        });
      } catch (error) {
        console.error('Error during file upload:', error);
        setDialogInfo({
          open: true,
          title: 'Error',
          message: error,
        });
      }
    };
  
    fileInput.click();
  };
  

  const handleOpenMessages = async (row) => {
    setCurrentRow(row);
  
    const invoiceId = row.original._id;
    let commentsThread = [...row.original.commentsThread];
    const unreadComments = commentsThread.filter(comment => 
      (comment.author === 'Client' || comment.author === 'Associate' || comment.author === 'Outlet') &&
      !comment.read
    );
  
    if (unreadComments.length > 0) {
      commentsThread = commentsThread.map(comment => 
        unreadComments.includes(comment) ? { ...comment, read: true } : comment
      );
      const { author, fromAndTo } = unreadComments[0];
      await updateComments({ invoiceId, author, fromAndTo });
      const updatedRow = { 
        ...row.original, 
        commentsThread 
      };
      setCurrentRow({ ...row, original: updatedRow });
    }
    setOpenMessages(true);
  };
  

  const handleCloseMessages = () => {
    setOpenMessages(false);
    setCurrentRow(null);
  };

  const handleSendMessage = async() => {
    if (message.trim()) {
      if (!currentRow.original.commentsThread) {
        currentRow.original.commentsThread = [];
      }
      currentRow.original.commentsThread.push({ 
        author: 'Admin', 
        message: message, 
        fromAndTo: messageView === 'Client' ? '1' : '2', 
        timestamp: new Date().toISOString() 
      });
      const updateData = {
          id: currentRow.original._id,
          commentsThread: currentRow.original.commentsThread
      };
      await updateUser(updateData);
      setMessage("");
    }
  };

  const handleSelectRow = (row, checked) => {
    
    // Update selected row IDs
    setSelectedRows((prev) =>
      checked ? [...prev, row.original._id] : prev.filter((id) => id !== row.original._id)
    );
    
    // Update selected row data
    setSelectedRowData((prev) =>
      checked ? [...prev, row.original] : prev.filter((data) => data._id !== row.original._id)
    );
  };

  const handleSelectItem = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSendToAssociates = () => {
    setSelectionType('associates');
    setSelectedItems([]); // Reset selection
    setOpenSelection(true);
  };

  const handleSendToOutlets = () => {
    setSelectionType('outlets');
    setSelectedItems([]); // Reset selection
    setOpenSelection(true);
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'select',
      header: '',
      size: 10,
      Cell: ({ row }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedRows.includes(row.original._id)}
              onChange={(e) => handleSelectRow(row, e.target.checked)}
            />
          }
        />
      ),
      size: 50,
    },
    {
      accessorKey: 'createdAt', header: 'CreatedAt', enableEditing: false, size: 100,
      Cell: ({ row }) => (
        displayAsIST(row.original.createdAt)
      ),
    },
    { accessorKey: 'clientName', header: 'Client', size: 120,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.clientName,
        helperText: validationErrors?.clientName,
        onFocus: () => setValidationErrors({ ...validationErrors, clientName: undefined }),
      },
    },
    { accessorKey: 'service', header: 'Service', size: 120,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.service,
        helperText: validationErrors?.service,
        onFocus: () => setValidationErrors({ ...validationErrors, service: undefined }),
      },
    },
    { accessorKey: 'sub_category', header: 'SubCategory', size: 120,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.subCategory,
        helperText: validationErrors?.subCategory,
        onFocus: () => setValidationErrors({ ...validationErrors, subCategory: undefined }),
      },
    },
    { accessorKey: 'documents', header: 'Documents', size: 150,
      Cell: ({ row }) => (
        <Link
          component="button"
          variant="body2"
          onClick={() => handleOpen(row)}
        >
          View Documents
        </Link>
      ),
    },
    { accessorKey: 'status', header: 'Status', size: 100,
      Cell: ({ row }) => (
        <Select
          value={row.original.status}
          onChange={(e) => handleChangeStatus(e, row)}
          error={!!validationErrors?.status}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: row.original.status === 'Finished' ? 'green' : row.original.status === 'Processing' ? 'orange' : 'red',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: row.original.status === 'Finished' ? 'green' : row.original.status === 'Processing' ? 'orange' : 'red',
            },
            '& .MuiSelect-select': {
              color: row.original.status === 'Finished' ? 'green' : row.original.status === 'Processing' ? 'orange' : 'red',
            },
          }}
        >
          <MenuItem value="Initiated">Initiated</MenuItem>
          <MenuItem value="Processing">Processing</MenuItem>
          <MenuItem value="Finished">Finished</MenuItem>
        </Select>
      ),
    },
  ], [validationErrors, selectedRows]);  

  const { data: fetchedInvoices = fakeData, isLoading: isLoadingInvoices, isError: isLoadingInvoicesError, isFetching: isFetchingInvoices, refetch } = useGetInvoices();
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: updateDocuments } = useUpdateDocuments();
  const { mutateAsync: updateComments } = useMarkMessagesAsRead();
  const { mutateAsync: deleteUser } = useDeleteUser();

  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
      deleteUser(row.original._id);
    }
  };

  const handleChangeStatus = (event, row) => {
    const newStatus = event.target.value;
    row.original.status = newStatus;
    const updateData = {
      id: row.original._id,
      status: row.original.status
  };
    updateUser(updateData);
  };

  const findUserDetails = (id) => {
    return associates.find(a => a._id === id) || outlets.find(o => o._id === id);
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedInvoices,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableEditing: true,
    getRowId: (row) => row.id,
    initialState :{
      pagination: { pageSize: 5 },
      sorting: [{ id: 'createdAt', desc: true }]
    },
    muiToolbarAlertBannerProps: isLoadingInvoicesError
      ? { color: 'error', children: 'Error loading data' }
      : undefined,
    muiTableContainerProps: { sx: { minHeight: '500px' } },
    state: {
      isLoading: isLoadingInvoices,
      showAlertBanner: isLoadingInvoicesError,
      showProgressBars: isFetchingInvoices,
    },
    renderRowActions: ({ row, table }) => {
      const hasUnreadMessages = row.original.commentsThread?.filter(comment => !comment.read && (comment.author === 'Client' || comment.author === 'Associate' || comment.author === 'Outlet'));
      return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Message">
          {hasUnreadMessages?.length > 0 ? 
          <IconButton color="secondary" onClick={() => handleOpenMessages(row)}>
            <Badge badgeContent={hasUnreadMessages.length} color="error">
              <MessageIcon />
            </Badge>
          </IconButton> :
          <IconButton color="primary" onClick={() => handleOpenMessages(row)}>
            <MessageIcon />
          </IconButton>
        }
        </Tooltip>
        <Tooltip title="Upload Documents">
            <IconButton color="primary" onClick={() => handleUpload(row)}>
              <UploadFileIcon />
            </IconButton>
          </Tooltip>
      </Box>
    )},
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {refetch()}}
      >
        Refresh
      </Button>
    ),
    renderDetailPanel: ({ row }) => {
      const userDetails = findUserDetails(row.original.assignedTo);

      return (
        <Box sx={{ padding: '16px', backgroundColor: '#f5f5f5', borderRadius: '20px', display: 'inline-block', minWidth:'250px' }}>
          {userDetails ? (
            <>
            <Typography variant="h6" gutterBottom>
              Invoice Assigned Details
            </Typography>
            <Divider sx={{ marginY: '8px' }} />

            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              {/* Left Side: User Details */}
              <Box sx={{ paddingRight: '16px' }}>
                <Typography variant="body1" gutterBottom>
                  <strong>Assigned to:</strong> {userDetails?.name || 'Unknown'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Role:</strong> {userDetails?.role || 'N/A'}
                </Typography>
              </Box>
            </Box>
            </>
          ) : (
            <Typography variant="body1">
              This invoice is not currently assigned to anyone.
            </Typography>
          )}
        </Box>
      );
    },
    // Enable row expansion
    enableRowSelection: false, // Optional, if you don't want row selection
    enableRowExpansion: true,
    getRowCanExpand: () => true, // Allows all rows to be expandable
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.original.assignedTo ? 'lightcyan' : 'inherit',
      },
    })}
  );

  const assignInvoiceToUser = async () => {
    let userId = selectedItems[0]
    try {
      const response = await axios.post('/invoices/assign', {
        invoices: selectedRowData.map(row => ({ invoiceId: row._id, userId })),
      });
  
      if (response.data.status === 200) {
        setDialogInfo({
          open: true,
          title: 'Success',
          message: `Invoices assigned successfully.`,
        });
        setOpenSelection(false);
        setSelectedRows([]);
      } else {
        setDialogInfo({
          open: true,
          title: 'Error',
          message: `Failed to create user: ${response.data.message}.`,
        });
      }
    } catch (error) {
      setDialogInfo({
        open: true,
        title: 'Error',
        message: `Failed to create user: ${error.message}.`,
      });
      console.error('Failed to assign invoices:', error);
    }
  };
  

  const renderCards = (items) => (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12} md={6} lg={4} key={item._id}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              position: 'relative',
              padding: 2,
              border: selectedItems.includes(item._id) ? '2px solid #1976d2' : '1px solid #ccc',
            }}
          >
            <Checkbox
              checked={selectedItems.includes(item._id)}
              onChange={() => handleSelectItem(item._id)}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            />
            <CardContent>
                <Typography variant="h6" component="div">
                  {item.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {item.profession}
                </Typography>
                {item.services.map((service) => (
                    <>
                    <Typography variant="h6" component="div">
                      {service.serviceName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Subcategories  : {service.subCategories.join(', ')}</strong>
                    </Typography>
                  </>
                ))}
              
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      {selectedRows.length >= 1 && <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleSendToAssociates} startIcon={<SendIcon />}>
          Send to Associates
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSendToOutlets} startIcon={<SendIcon />}>
          Send to Outlets
        </Button>
      </Box>}
      <ConfirmDialog
        open={dialogInfo.open}
        onClose={handleCloseDialog}
        title={dialogInfo.title}
        message={dialogInfo.message}
      />

      <Dialog open={openSelection} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectionType === 'associates' ? 'Select Associates' : 'Select Outlets'}
        </DialogTitle>
        <DialogContent>
          {renderCards(selectionType === 'associates' ? associates : outlets)}
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenSelection(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={assignInvoiceToUser} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ height: '100%', overflowY: 'auto' }}>
        <MaterialReactTable 
          table={table}
          renderDetailPanel={({ row }) => <InvoiceDetailPanel row={row} />}
          enableRowSelection={true}
          enableRowExpansion={true}
          getRowCanExpand={() => true}
          enableStickyHeader={true}  // Enables sticky header feature
        />
      </div>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>View Documents</DialogTitle>
        <DialogContent>
          <Typography variant="h4" sx={{ color: 'green', fontWeight:"bold", marginBottom:"5px" }}>Documents Uploaded by Client</Typography>
          {currentDocuments
          ?.filter((document) => document.fromAndTo === '1')
          .map((document, index) => {
            const fileExtension = document.filename.split('.').pop().toLowerCase();
            const renderDocument = () => {
              switch (fileExtension) {
                case 'pdf':
                  return <PdfViewer fileUrl={document.url} />;
                  case 'jpg':
                  case 'jpeg':
                  case 'png':
                  case 'gif':
                    return (<>
                      <img src={document.url} alt={`Document ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                        <button
                        onClick={() => window.open(document.url, '_blank')}
                        className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                      >
                        Download Document
                      </button>
                      </>
                      )
                default:
                  return (
                    <a
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      View/Download Document
                    </a>
                  );
              }
            };
            return(
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Document {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderDocument()}
              </AccordionDetails>
            </Accordion>
          )})}
          <Typography variant="h4" sx={{ color: 'green', fontWeight:"bold", marginBottom:"5px", marginTop:"5px" }}>Documents Uploaded by Assigned user</Typography>
          {currentDocuments
          ?.filter((document) => document.fromAndTo === '2')
          .map((document, index) => {
            const fileExtension = document.filename.split('.').pop().toLowerCase();
            const renderDocument = () => {
              switch (fileExtension) {
                case 'pdf':
                  return <PdfViewer fileUrl={document.url} />;
                  case 'jpg':
                  case 'jpeg':
                  case 'png':
                  case 'gif':
                    return (<>
                      <img src={document.url} alt={`Document ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                        <button
                        onClick={() => window.open(document.url, '_blank')}
                        className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                      >
                        Download Document
                      </button>
                      </>
                      )
                default:
                  return (
                    <a
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      View/Download Document
                    </a>
                  );
              }
            };
            return(
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Document {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderDocument()}
              </AccordionDetails>
            </Accordion>
          )})}
          <hr />
          <Typography variant="h4" sx={{ color: 'green', fontWeight:"bold", marginBottom:"5px", marginTop:"5px" }}>Documents Uploaded by Admin</Typography>
          {currentDocuments
          ?.filter((document) => document.fromAndTo === '3')
          .map((document, index) => {
            const fileExtension = document.filename.split('.').pop().toLowerCase();
            const renderDocument = () => {
              switch (fileExtension) {
                case 'pdf':
                  return <PdfViewer fileUrl={document.url} />;
                  case 'jpg':
                  case 'jpeg':
                  case 'png':
                  case 'gif':
                    return (<>
                      <img src={document.url} alt={`Document ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                        <button
                        onClick={() => window.open(document.url, '_blank')}
                        className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                      >
                        Download Document
                      </button>
                      </>
                      )
                default:
                  return (
                    <a
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="inline-flex items-center px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      View/Download Document
                    </a>
                  );
              }
            };
            return(
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Admin Document {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderDocument()}
              </AccordionDetails>
            </Accordion>
          )})}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMessages} onClose={handleCloseMessages} maxWidth="md" fullWidth>
        <DialogTitle>Thread Messages</DialogTitle>
        {/* Toggle between Client and Assigned User */}
        <Box display="flex" justifyContent="center" my={2}>
          <ToggleButtonGroup
            value={messageView}
            exclusive
            onChange={handleMessageViewChange}
            aria-label="Message View Toggle"
          >
            <ToggleButton value="Client" aria-label="Client Messages">
              Client
            </ToggleButton>
            {currentRow?.original.assignedTo && (
              <ToggleButton value="assigned" aria-label="Assigned User Messages">
                Assigned User
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Box>
        <DialogContent>
        {currentRow?.original.commentsThread
        .filter((msg) => messageView === 'Client' ? msg.fromAndTo === "1" : msg.fromAndTo === "2")
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .map((msg, index) => (
            <Typography key={index} variant="body2" sx={{ padding: '5px', backgroundColor: msg.author === 'Admin' ? 'lightblue' : 'lightgreen', borderRadius: '5px', marginBottom: '5px' }}>
              {msg.author}: {msg.message}
            </Typography>
          ))}
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            label="New Message"
            fullWidth
            multiline
            rows={4}
            sx={{ marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendMessage} variant="contained">Send</Button>
          <Button onClick={handleCloseMessages}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Hooks to fetch, update, and delete invoices
function useGetInvoices() {
  return useQuery({
    queryKey: ['invoices'],
    queryFn: async () => {
      const { data } = await axios.post('/invoices/get_all_invoices');
      // console.log(data.responseData)
      return data.responseData;
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (invoice) => {
      await axios.post(`/invoices/update/${invoice.id}`, invoice);
    },
    onMutate: async(newInvoiceInfo) => {
      await queryClient.cancelQueries(['invoices']);  // Optimistic updates - cancel existing fetch
      const previousInvoices = queryClient.getQueryData(['invoices']);
      queryClient.setQueryData(['invoices'], (prevInvoices) =>
        prevInvoices?.map((prevInvoice) =>
          prevInvoice.id === newInvoiceInfo.id ? newInvoiceInfo : prevInvoice
        ),
      );
      return { previousInvoices };
    },
    onSettled: () => {
      queryClient.invalidateQueries(['invoices']);  // Invalidate cache and refetch after mutation
    },
  });
}

function useUpdateDocuments() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData) => {
      await axios.post(`/invoices/reupload_documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onMutate: async (newInvoiceInfo) => {
      await queryClient.cancelQueries(['invoices']);  // Optimistic updates - cancel existing fetch
      
      // Update cache with new data
      const previousInvoices = queryClient.getQueryData(['invoices']);
      queryClient.setQueryData(['invoices'], (prevInvoices) =>
        prevInvoices?.map((prevInvoice) =>
          prevInvoice.id === newInvoiceInfo.id ? newInvoiceInfo : prevInvoice
        ),
      );
      return { previousInvoices };
    },
    onSettled: () => {
      queryClient.invalidateQueries(['invoices']);  // Invalidate cache and refetch after mutation
    },
  });
}

function useMarkMessagesAsRead() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ invoiceId, author, fromAndTo }) => {
      await axios.post(`/invoices/${invoiceId}/markMessagesAsRead`, {
        author,
        fromAndTo,
      });
    },
    onMutate: async ({ invoiceId, author, fromAndTo }) => {
      await queryClient.cancelQueries(['invoices']);
      const previousInvoices = queryClient.getQueryData(['invoices']);
      queryClient.setQueryData(['invoices'], (prevInvoices) =>
        prevInvoices?.map((invoice) =>
          invoice._id === invoiceId
            ? {
                ...invoice,
                commentsThread: invoice.commentsThread.map((message) =>
                  message.author === author && message.fromAndTo === fromAndTo
                    ? { ...message, read: true } // Mark as read in UI
                    : message
                ),
              }
            : invoice
        )
      );
      return { previousInvoices };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['invoices'], context.previousInvoices);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['invoices']);
    },
  });
}


function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invoiceId) => {
      await axios.post(`/invoices/delete/${invoiceId}`);
    },
    onMutate: (invoiceId) => {
      queryClient.setQueryData(['invoices'], (prevInvoices) =>
        prevInvoices?.filter((invoice) => invoice.id !== invoiceId),
      );
    },
  });
}

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Box m="20px">
        <Header title="Client Invoices" subtitle="Manage the Invoices of clients" />
        <Box
          m="40px 0 0 0"
          height="75vh"
        >
          <Example />
        </Box>
      </Box>
    </QueryClientProvider>
  );
}
