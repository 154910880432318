import React, { useState, useEffect } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField, Grid } from '@mui/material';

import { Fade } from 'react-awesome-reveal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form from 'elements/Form';
import Button from 'elements/Button';

import axios from '../axiosConfig';


import commonFunctions from '../common.js';

export default function DiscussForm(props) {
  const { data, resetForm } = props;

  const [step, setStep] = useState(1);
  const [francaiseStep, setFrancaiseStep] = useState(1);
  const [associateStep, setAssociateStep] = useState(1);
  const [phoneOTP, setPhoneOTP] = useState();
  const [emailOTP, setEmailOTP] = useState();
  const [timer, setTimer] = useState(0);
  const [allowRetry, setAllowRetry] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [selectedTab, setSelectedTab] = useState('client');
  const [portfolioData, setPortfolioData] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setAllowRetry(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (step === 2 || francaiseStep == 2 || associateStep == 2) {
      setTimer(60); // Reset timer to 60 seconds
      setAllowRetry(false);
    }
  }, [step, francaiseStep, associateStep]);

  useEffect(() => {
    fetchPortfolioData();
  },[1])


  const fetchPortfolioData = async () => {
    try {
      const response = await axios.post('/services/get_all_services', {});
      let data = response.data.responseData.filter((item) => item.status === "Active");
      setPortfolioData(data);
    } catch (error) {
      console.log(error.message);
    }
  };

const handleServiceToggle = (service) => {
  const allSelected =
    selectedServices[service.name] &&
    Object.values(selectedServices[service.name]).every((value) => value === true);

  const updatedSelectedServices = {
    ...selectedServices,
    [service.name]: service.subCategories.reduce((acc, subcategory) => {
      acc[subcategory.name] = !allSelected; 
      return acc;
    }, {}),
  };
  setSelectedServices(updatedSelectedServices);
};

  

  const handleServiceChange = (event) => {
    const value = event.target.value;
    if (value[0] === undefined) return;
    const newSelectedServices = { ...selectedServices };

    value.forEach((val) => {
      const [service, subcategory] = val.split(' - ');
      if (!newSelectedServices[service]) {
        newSelectedServices[service] = {};
      }
      newSelectedServices[service][subcategory] = !newSelectedServices[service][subcategory];
    });
    setSelectedServices(newSelectedServices);
  };

  const ProfessionOptions = [
    "Lawyer",
    "Chartered Accountant",
    "Company Secretary",
    "Cost Management Accountant",
    "Document Writer",
    "Tax Consultants",
    "Other - Degree Holder"
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  };
  
  const ProfessionSelect = ({ value, onChange }) => (
    <select
      id="profession"
      name="profession"
      value={value}
      onChange={onChange}
      className="p-4 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple"
    >
      <option value="" disabled>Select your Profession</option>
      {ProfessionOptions?.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))}
    </select>
  );


  const sendOtpStep = (role) => {
    const {firstName, lastName, email, phoneNumber} = data;
    var nameError = firstName != '';
    var emailError = commonFunctions.emailValidator(email);
    var phoneError = commonFunctions.phoneNumberVaidator(phoneNumber);
    if(nameError && emailError && phoneError){
      otpApiCall(role);
    }else{
      if(!nameError){
        toast.error("Firstname can't be empty");
      }

      if(!emailError){
        toast.error("Email format is wrong");
      }

      if(!phoneError) {
        toast.error("Phone number format is wrong");
      }
    }
  }

  const otpApiCall = async(role) => {
    try{
      const response = await axios.post("/api/auth/generate-otp", {mobileNumber : data.phoneNumber, email : data.email });
      if(response.data.status === 200){
        toast.success("OTP sent!");
        if (role === "Client") setStep(2);
        if (role === "Outlet") setFrancaiseStep(2);
        if (role === "Associate") setAssociateStep(2);
      }
      else{
        toast.error(response.data.message);
      }
    }
    catch(e){
      toast.error("An error has occured");
    }
  }

  const otpVerifyCall = async(role) => {
    try{
      const response  = await axios.post("/api/auth/verify-otp", { email : data.email, 
        phoneNumber : data.phoneNumber,
        emailOTP : emailOTP, 
        phoneOTP : phoneOTP });
        if(response.data.status === 200){
          toast.success("OTP validated");
          if (role === "Client") setStep(3);
          if (role === "Outlet") setFrancaiseStep(3);
          if (role === "Associate") setAssociateStep(3);
        }
        else{
          toast.error(response.data.message);
        }
    }
    catch(e){
      toast.error("Some error has occured");
    }
  }

  const submitForm = (role) => {

    if (role === "Client"){
      const { firstName, lastName, email, phoneNumber, password, confirmPassword } = data;

    if (firstName !== '' && lastName !== '' && email !== '' && phoneNumber !== '' && password !== '' && confirmPassword !== '') {
      if (password !== confirmPassword) toast.error('Please ensure the password and confirm password fields are same.');
      else if (password.length < 6) toast.error('Please ensure the password is at least 6 characters long.');
      else {
        try {
          axios.post('/api/auth/register', {
            role,
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
          }).then((res) => {
            if (res.data.status === 200) {
              toast.success('Success! Please Login with your credentials');
              resetForm();
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              toast.error(res.data.message);
            }
          });
        } catch (err) {
          resetForm();
        }
      }
    } else {
      toast.error('Please fill out the blank form.');
    }
    } else if (role === "Outlet" || role === "Associate") {
      const { firstName, email, phoneNumber, profession, register_office_name, address } = data;

        if (firstName !== '' && profession !== '' && email !== '' && phoneNumber !== '' && address.district !== '' && address.taluk !== '' && address.village !== '') {
            try {
              axios.post('/api/auth/register', {
                role,
                name:firstName,
                profession,
                email,
                mobile:phoneNumber,
                register_office_name,
                services:selectedServices,
                address
              }).then((res) => {
                if (res.data.status === 200) {
                  toast.success('Success! Credentials will be sent to registered email. Please Login with your credentials.');
                  resetForm();
                  setTimeout(() => {
                    window.location.reload();
                  }, 5000);
                } else {
                  toast.error(res.data.message);
                }
              });
            } catch (err) {
              resetForm();
            }
        } else {
          toast.error('Please fill out the blank form.');
        }
    } else {
        toast.error("Some error Occured. Please try again later.");
    }
    
  };

  const checkResgistration = async(role) => {
    const {email} = data;
    if(email && email!=''){
      try{
        axios.post('/api/auth/check_registration', {username:email})
        .then((res) => {
          if(res.data.message == 'User_Not_Found'){
            toast.success('Email is available!');
            sendOtpStep(role);
          }else if(res.data.message == 'User_Found'){
            toast.error("You're already registered. Please login.");
          }
        });
      }catch(e){
        toast.error("An error has occured, please try again later.");
      }
    }
  }

  return (
    <section className="flex flex-col container mx-auto mt-10 justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 sm:p-10 md:p-12 lg:p-16 max-w-4xl mx-auto">
      <Fade bottom>
        <h1 className="text-5xl text-theme-blue text-center font-bold">Lets Connect</h1>
        <Tabs className="flex flex-col px-4 justify-center items-center">
          <TabList>
            <div className="flex flex-row mb-5">
              <Tab>
                <Button 
                  className={`font-normal px-5 py-2 mr-3 text-lg border rounded-full transition duration-300 focus:outline-none 
                  ${selectedTab === 'client' ? 'text-white bg-theme-purple border-theme-purple' : 'text-theme-purple border-theme-purple hover:bg-theme-purple hover:text-white focus:bg-theme-purple focus:text-white'}`}
                 onClick= {() => setSelectedTab('client')}>Client</Button>
              </Tab>
              <Tab>
                <Button
                 className={`font-normal px-5 py-2 mr-3 text-lg border rounded-full transition duration-300 focus:outline-none 
                 ${selectedTab === 'franchise' ? 'text-white bg-theme-purple border-theme-purple' : 'text-theme-purple border-theme-purple hover:bg-theme-purple hover:text-white focus:bg-theme-purple focus:text-white'}`}
                 onClick= {() =>setSelectedTab('franchise')}
                 >Franchisee / outlet</Button>
              </Tab>
              <Tab>
                <Button
                 className={`font-normal px-5 py-2 mr-3 text-lg border rounded-full transition duration-300 focus:outline-none 
                 ${selectedTab === 'associate' ? 'text-white bg-theme-purple border-theme-purple' : 'text-theme-purple border-theme-purple hover:bg-theme-purple hover:text-white focus:bg-theme-purple focus:text-white'}`}
                 onClick= {() =>setSelectedTab('associate')}
                 >Associate</Button>
              </Tab>
            </div>
          </TabList>
          <TabPanel>
            <p className="font-light text-lg text-gray-400 text-center mb-12">          
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Please fill out the form below as Client to discuss your project and we'll get back to you in less than 24 hours.
            </p>
            <div className="flex flex-col">
              {step === 1 && <div className="flex flex-col sm:flex-row mx-auto">
                <Form
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={data.firstName}
                  placeholder="First Name"
                  className=""
                  errorResponse='Please Enter'
                  onChange={props.onChange}
                />
                <Form
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={data.lastName}
                  placeholder="Last Name"
                  className=""
                  onChange={props.onChange}
                />
              </div>}

              { step === 1 && <div className="flex flex-col sm:flex-row mx-auto">
                <Form
                  id="email"
                  name="email"
                  type="email"
                  value={data.email}
                  placeholder="Email address"
                  className=""
                  errorResponse='Please enter correct email.'
                  onChange={props.onChange}
                />
                <Form
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  value={data.phoneNumber}
                  placeholder="Contact number"
                  className=""
                  onChange={props.onChange}
                />
              </div>}

              { step === 2 && <div className="flex flex-col sm:flex-row mx-auto">
                {/* <Form
                  id="phoneOTP"
                  name="phoneOTP"
                  type="password"
                  value={phoneOTP}
                  placeholder="Phone OTP"
                  className=""
                  onChange={(e) => setPhoneOTP(e.target.value)}
                /> */}
                <Form
                  id="emailOTP"
                  name="emailOTP"
                  type="password"
                  value={emailOTP}
                  placeholder="Email OTP"
                  className=""
                  onChange={(e) => setEmailOTP(e.target.value)}
                />
              </div>}

              { step === 3 && <div className="flex flex-col sm:flex-row mx-auto">
                <Form
                  id="password"
                  name="password"
                  type="password"
                  value={data.password}
                  placeholder="Password"
                  className=""
                  onChange={props.onChange}
                />
                <Form
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={data.confirmPassword}
                  placeholder="confirmPassword"
                  className=""
                  onChange={props.onChange}
                />
              </div>}

              { step === 3 && <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={() => submitForm('Client')}
              >
                Submit
              </Button>}
              { step === 1 && <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={()=>checkResgistration("Client")}
              >
                OTP Validate
              </Button>}
              { step === 2 &&
              <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={()=>otpVerifyCall("Client")}
              >
                Verify OTP
                </Button> }

              {step === 2 && (timer > 0 ? 
                <p className='text-sm mt-5 mx-auto text-gray-500 rounded-full focus:outline-none'>Resend OTP/Go back in {timer} seconds</p>
                :
                <p className="text-sm mt-5 mx-auto text-gray-500 rounded-full hover:bg-dark-theme-purple transition duration-200 focus:outline-none cursor-pointer" onClick={() => setStep(1)}>Go back to resend OTP</p>
              )}

            </div>
          </TabPanel>
          <TabPanel>
            <p className="font-light text-lg text-gray-400 text-center mb-12">
              Please fill out the form below as Franchisee/Outlet and we'll get back to you in less than 24 hours.
            </p>
            <div className="flex flex-col">
              {francaiseStep === 1 && <div className="flex flex-col">
              <div className="flex flex-col sm:flex-row mx-auto">
                <Form
                  id="name"
                  name="firstName"
                  type="text"
                  value={data.firstName}
                  placeholder="Your name"
                  className=""
                  errorResponse='Please Enter'
                  onChange={props.onChange}
                />
                <Form
                  id="email"
                  name="email"
                  type="email"
                  value={data.email}
                  placeholder="Your email address"
                  className="mb-4 sm:mb-0 sm:mr-4"
                  onChange={props.onChange}
                />
                  <Form
                    id="phone"
                    name="phoneNumber"
                    type="tel"
                    value={data.phoneNumber}
                    placeholder="Your contact number"
                    className="mb-4 sm:mb-0 sm:mr-4"
                    onChange={props.onChange}
                  />
                </div>
                <div className="flex flex-col sm:flex-row mx-auto">
                  <div className="w-full sm:w-auto mb-4">
                    <ProfessionSelect
                      value={data.profession}
                      onChange={props.onChange}
                    />
                  </div>
                </div>
              </div>}
              { francaiseStep === 2 && <div className="flex flex-col sm:flex-row mx-auto">
                {/* <Form
                  id="phoneOTP"
                  name="phoneOTP"
                  type="password"
                  value={phoneOTP}
                  placeholder="Phone OTP"
                  className=""
                  onChange={(e) => setPhoneOTP(e.target.value)}
                /> */}
                <Form
                  id="emailOTP"
                  name="emailOTP"
                  type="password"
                  value={emailOTP}
                  placeholder="Email OTP"
                  className=""
                  onChange={(e) => setEmailOTP(e.target.value)}
                />
              </div>}
              {francaiseStep === 3 && (
                <div className="flex flex-col mx-auto space-y-4">
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <Form
                      id="register_office_name"
                      name="register_office_name"
                      type="text"
                      value={data.register_office_name}
                      placeholder="Registration No.(Optional)"
                      className=""
                      onChange={props.onChange}
                      aria-required="false"
                    />
                  </div>
                  <FormControl fullWidth>
                    <InputLabel>Pick the Services You Specialize In</InputLabel>
                    <Select
                      multiple
                      value={[]}
                      onChange={handleServiceChange}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      required
                    >
                      {portfolioData?.map((service) => [
                      <MenuItem
                        key={service._id}
                        onClick={() => handleServiceToggle(service)}
                      >
                        <Checkbox
                          checked={
                            selectedServices[service.name] &&
                            Object.values(selectedServices[service.name]).every(value => value === true)
                          }
                        />
                        <strong style={{ fontWeight: 'bold', color: '#3f51b5', cursor: 'pointer' }}>
                          {service.name} 
                        </strong>
                      </MenuItem>,
                      
                      ...service.subCategories?.map((subcategory) => (
                        <MenuItem
                          key={`${service._id}-${subcategory._id}`} // Subcategory unique key
                          value={`${service.name} - ${subcategory.name}`} // Display value
                          style={{ paddingLeft: '50px' }} // Indent for subcategories
                        >
                          <Checkbox
                            checked={selectedServices[service.name]?.[subcategory.name] || false}
                          />
                          <ListItemText primary={subcategory.name} />
                        </MenuItem>
                      ))
                    ])}

                    </Select>
                  </FormControl>
                </div>
              
                <Box
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  padding: '16px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <InputLabel>Address Details</InputLabel>
                <br />
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="state"
                      name="state"
                      label="State"
                      type="text"
                      value={data.address.state}
                      onChange={props.onChange}
                      fullWidth
                      required
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <FormControl sx={{width: "100%"}}>
                    <InputLabel>District</InputLabel>
                    <Select
                      id="district"
                      name="district"
                      label="District"
                      value={data.address.district}
                      onChange={props.handleCityChange}
                      fullWidth
                      required
                    >
                      {props.districts.map((district) => (
                        <MenuItem key={district.district_code} value={district.district_name}>
                          {district.district_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <FormControl sx={{width: "100%"}}>
                    <InputLabel>Taluk</InputLabel>
                    <Select
                      id="taluk"
                      name="taluk"
                      placeholder='Taluk'
                      label="Taluk"
                      value={data.address.taluk}
                      onChange={props.handleTalukChange}
                      fullWidth
                      required
                    >
                      {props.taluks.map((taluk) => (
                        <MenuItem key={taluk.taluk_code} value={taluk.taluk_name}>
                          {taluk.taluk_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <FormControl sx={{width: "100%"}}>
                    <InputLabel>Village</InputLabel>
                    <Select
                      id="village"
                      name="village"
                      placeholder='Village'
                      label="Village"
                      value={data.address.village}
                      onChange={props.handleVillageChange}
                      fullWidth
                      required
                    >
                      {props.villages.map((village) => (
                        <MenuItem key={village.village_code} value={village.village_name}>
                          {village.village_name}
                        </MenuItem>
                      ))}
                    </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="noLandmark"
                      name="landmark"
                      label="No/Landmark"
                      type="text"
                      value={data.address.landmark}
                      onChange={props.onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="street"
                      name="street"
                      label="Street/Road"
                      type="text"
                      value={data.address.street}
                      onChange={props.onChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <p className="text-red-500 text-sm mt-2">* Required</p>
              </Box>
              </div>
                            
              )}
              { francaiseStep === 3 && <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={() => submitForm('Outlet')}
              >
                Submit
              </Button>}
              { francaiseStep === 1 && <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={()=>checkResgistration("Outlet")}
              >
                OTP Validate
              </Button>}
              { francaiseStep === 2 &&
              <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={()=>otpVerifyCall("Outlet")}
              >
                Verify OTP
                </Button> }

              {francaiseStep === 2 && (timer > 0 ? 
                <p className='text-sm mt-5 mx-auto text-gray-500 rounded-full focus:outline-none'>Resend OTP/Go back in {timer} seconds</p>
                :
                <p className="text-sm mt-5 mx-auto text-gray-500 rounded-full hover:bg-dark-theme-purple transition duration-200 focus:outline-none cursor-pointer" onClick={() => setFrancaiseStep(1)}>Go back to resend OTP</p>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <p className="font-light text-lg text-gray-400 text-center mb-12">
              Please fill out the form below as Associate and we'll get back to you in less than 24 hours.
            </p>
            <div className="flex flex-col">
              { associateStep === 1 && <div className="flex flex-col">
              <div className="flex flex-col sm:flex-row mx-auto">
                <Form
                  id="name"
                  name="firstName"
                  type="text"
                  value={data.firstName}
                  placeholder="Your name"
                  className="mb-4 sm:mb-0 sm:mr-4"
                  onChange={props.onChange}
                />
                <Form
                  id="email"
                  name="email"
                  type="email"
                  value={data.email}
                  placeholder="Your email address"
                  className="mb-4 sm:mb-0 sm:mr-4"
                  onChange={props.onChange}
                />
                  <Form
                    id="phone"
                    name="phoneNumber"
                    type="tel"
                    value={data.phoneNumber}
                    placeholder="Your contact number"
                    className="mb-4 sm:mb-0 sm:mr-4"
                    onChange={props.onChange}
                  />
                </div>
                <div className="flex flex-col sm:flex-row mx-auto">
                  <div className="w-full sm:w-auto mb-4">
                    <ProfessionSelect
                      value={data.profession}
                      onChange={props.onChange}
                    />
                  </div>
                </div>
                </div>}

                { associateStep === 2 && <div className="flex flex-col sm:flex-row mx-auto">
                {/* <Form
                  id="phoneOTP"
                  name="phoneOTP"
                  type="password"
                  value={phoneOTP}
                  placeholder="Phone OTP"
                  className=""
                  onChange={(e) => setPhoneOTP(e.target.value)}
                /> */}
                <Form
                  id="emailOTP"
                  name="emailOTP"
                  type="password"
                  value={emailOTP}
                  placeholder="Email OTP"
                  className=""
                  onChange={(e) => setEmailOTP(e.target.value)}
                />
              </div>}
              {associateStep === 3 && (
                <div className="flex flex-col mx-auto space-y-4" style={{width:"70%"}}>
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <Form
                      id="register_office_name"
                      name="register_office_name"
                      type="text"
                      value={data.register_office_name}
                      placeholder="Registration No.(Optional)"
                      className=""
                      onChange={props.onChange}
                      aria-required="false"
                    />
                  </div>
                  <FormControl fullWidth>
                    <InputLabel>Pick the Services You Specialize In</InputLabel>
                    <Select
                      multiple
                      value={[]}
                      onChange={handleServiceChange}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      required
                    >
                      {portfolioData?.map((service) => [
                      <MenuItem
                        key={service._id}
                        onClick={() => handleServiceToggle(service)}
                      >
                        <Checkbox
                          checked={
                            selectedServices[service.name] &&
                            Object.values(selectedServices[service.name]).every(value => value === true)
                          }
                        />
                        <strong style={{ fontWeight: 'bold', color: '#3f51b5', cursor: 'pointer' }}>
                          {service.name} 
                        </strong>
                      </MenuItem>,
                      
                      ...service.subCategories?.map((subcategory) => (
                        <MenuItem
                          key={`${service._id}-${subcategory._id}`} // Subcategory unique key
                          value={`${service.name} - ${subcategory.name}`} // Display value
                          style={{ paddingLeft: '50px' }} // Indent for subcategories
                        >
                          <Checkbox
                            checked={selectedServices[service.name]?.[subcategory.name] || false}
                          />
                          <ListItemText primary={subcategory.name} />
                        </MenuItem>
                      ))
                    ])}

                    </Select>
                  </FormControl>
                </div>
                
                <Box
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  padding: '16px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <InputLabel>Address Details</InputLabel>
                <br />
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="state"
                      name="state"
                      label="State"
                      type="text"
                      value={data.address.state}
                      onChange={props.onChange}
                      fullWidth
                      required
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <FormControl sx={{width: "100%"}}>
                    <InputLabel>District</InputLabel>
                    <Select
                      id="district"
                      name="district"
                      label="District"
                      value={data.address.district}
                      onChange={props.handleCityChange}
                      fullWidth
                      required
                    >
                      {props.districts.map((district) => (
                        <MenuItem key={district.district_code} value={district.district_name}>
                          {district.district_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <FormControl sx={{width: "100%"}}>
                    <InputLabel>Taluk</InputLabel>
                    <Select
                      id="taluk"
                      name="taluk"
                      placeholder='Taluk'
                      label="Taluk"
                      value={data.address.taluk}
                      onChange={props.handleTalukChange}
                      fullWidth
                      required
                    >
                      {props.taluks.map((taluk) => (
                        <MenuItem key={taluk.taluk_code} value={taluk.taluk_name}>
                          {taluk.taluk_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <FormControl sx={{width: "100%"}}>
                    <InputLabel>Village</InputLabel>
                    <Select
                      id="village"
                      name="village"
                      placeholder='Village'
                      label="Village"
                      value={data.address.village}
                      onChange={props.handleVillageChange}
                      fullWidth
                      required
                    >
                      {props.villages.map((village) => (
                        <MenuItem key={village.village_code} value={village.village_name}>
                          {village.village_name}
                        </MenuItem>
                      ))}
                    </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="noLandmark"
                      name="landmark"
                      label="No/Landmark"
                      type="text"
                      value={data.address.landmark}
                      onChange={props.onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="street"
                      name="street"
                      label="Street/Road"
                      type="text"
                      value={data.address.street}
                      onChange={props.onChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <p className="text-red-500 text-sm mt-2">* Required</p>
              </Box>

              </div>
                            
              )}
              { associateStep === 3 && <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={() => submitForm('Associate')}
              >
                Submit
              </Button>}
              { associateStep === 1 && <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={()=>checkResgistration("Associate")}
              >
                OTP Validate
              </Button>}
              { associateStep === 2 &&
              <Button
                className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
                type="button"
                onClick={()=>otpVerifyCall("Associate")}
              >
                Verify OTP
                </Button> }

              {associateStep === 2 && (timer > 0 ? 
                <p className='text-sm mt-5 mx-auto text-gray-500 rounded-full focus:outline-none'>Resend OTP/Go back in {timer} seconds</p>
                :
                <p className="text-sm mt-5 mx-auto text-gray-500 rounded-full hover:bg-dark-theme-purple transition duration-200 focus:outline-none cursor-pointer" onClick={() => setAssociateStep(1)}>Go back to resend OTP</p>
              )}
            </div>
          </TabPanel>
        </Tabs>
      </Fade>

      <ToastContainer />
      </div>
    </section>
  );
}
